import React from "react"

import Layout from "../components/common/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Página no encontrada" />
    <h1>{"Al parecer, la página que intentas visitar no existe."}</h1>
  </Layout>
)

export default NotFoundPage
